import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Flex, Box } from '@qga/roo-ui/components';
import { getCampaignName, getCampaignTitle, getCampaignMessage } from 'store/campaign/campaignSelectors';
import CampaignMessageLink from './CampaignMessageLink';
import { BannerText } from './primitives';
import { CAMPAIGN_BANNER_ENABLED } from 'config';
import { getIsExclusive } from 'store/property/propertySelectors';
import useViewPromotionEvent from 'hooks/useViewPromotionEvent';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { getOptimizely } from 'lib/optimizely';

export const DEFAULT_TITLE = 'Enjoy more Classic Hotel Rewards';
export const DEFAULT_MESSAGE = `when you use points.`;

const CampaignMessaging = ({ showDefaultMessage, ...rest }) => {
  const ref = useRef(null);
  const name = useSelector(getCampaignName);
  const globalCampaignTitle = useSelector(getCampaignTitle);
  const globalCampaignMessage = useSelector(getCampaignMessage);
  const { isGreaterThanOrEqualToBreakpoint } = useBreakpoints();
  const isNotMobile = isGreaterThanOrEqualToBreakpoint(0);
  const isExclusive = useSelector(getIsExclusive);
  const optimizely = getOptimizely();

  const [showCampaignBanner, setShowCampaignBanner] = useState(false);
  const [titleToDisplay, setTitleToDisplay] = useState(DEFAULT_TITLE);
  const [messageToDisplay, setMessageToDisplay] = useState(DEFAULT_MESSAGE);

  useViewPromotionEvent({
    ref,
    promotion: {
      name,
      slot: 'banner',
    },
  });

  const checkOptimizelyFeatures = async () => {
    await optimizely.onReady();

    const isYieldifyCampaignEnabled = optimizely.isFeatureEnabled('qantas-hotels-yieldify-campaign');
    const isLocalCampaignEnabled = optimizely.isFeatureEnabled('qantas-hotels-blue-banner-message');
    const localCampaignConfig = optimizely.getAllFeatureVariables('qantas-hotels-blue-banner-message');

    const localCampaignTitle = localCampaignConfig?.title;
    const localCampaignMessage = localCampaignConfig?.message;
    const hasCampaign = !!globalCampaignTitle || isLocalCampaignEnabled;
    const campaignToDisplayTitle = globalCampaignTitle ?? (isLocalCampaignEnabled ? localCampaignTitle : null);
    const campaignToDisplayMessage = globalCampaignMessage ?? (isLocalCampaignEnabled ? localCampaignMessage : null);

    setShowCampaignBanner((hasCampaign || showDefaultMessage) && !isExclusive && !isYieldifyCampaignEnabled);

    const titleToDisplay = campaignToDisplayTitle ?? DEFAULT_TITLE;
    const messageToDisplay = campaignToDisplayMessage ?? DEFAULT_MESSAGE;

    setTitleToDisplay(titleToDisplay);
    setMessageToDisplay(messageToDisplay);
  };

  useEffect(() => {
    checkOptimizelyFeatures();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!CAMPAIGN_BANNER_ENABLED || !showCampaignBanner) return null;

  return (
    <Box {...rest} ref={ref} data-testid="campaign-banner-wrapper">
      <Flex bg="bayBlue30" justifyContent="center" alignItems="center" p={3} flexDirection={['column', 'row']}>
        <CampaignMessageLink title={titleToDisplay}>
          <BannerText data-testid="title" dangerouslySetInnerHTML={{ __html: titleToDisplay }} />
          {!isEmpty(messageToDisplay) && (
            <>
              {isNotMobile && (
                <BannerText mx="1" xs>
                  -
                </BannerText>
              )}
              <BannerText data-testid="message">{messageToDisplay}</BannerText>
            </>
          )}
        </CampaignMessageLink>
      </Flex>
    </Box>
  );
};

CampaignMessaging.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  showDefaultMessage: PropTypes.bool,
};

CampaignMessaging.defaultProps = {
  title: null,
  message: null,
  showDefaultMessage: true,
};

export default CampaignMessaging;
