import { Decimal } from 'decimal.js';

//NOTE - don't use this directly. Opt for using the `usePointsConverters` hook as it will
// correctly select the tiers based on context

const convertCashToPoints = ({ cash, conversionTiers }) => {
  if (cash === null || cash === undefined) return cash;

  cash = new Decimal(cash);
  const absoluteCashAmount = cash.abs();

  const points = conversionTiers
    .reduce((total, tier) => {
      if (absoluteCashAmount.greaterThanOrEqualTo(tier.min)) {
        const tierMax = (tiermax) => (tiermax ? tiermax : new Decimal(Infinity));
        const pointsAmountForTier = Decimal.min(tierMax(tier.max), absoluteCashAmount).minus(tier.min).dividedBy(tier.rate);
        return total.plus(pointsAmountForTier);
      } else {
        return total;
      }
    }, new Decimal(0))
    .toDecimalPlaces(0, Decimal.ROUND_HALF_EVEN);

  const resultAmount = cash.isNegative() ? points.neg() : points;
  return resultAmount;
};

export default convertCashToPoints;
