import { Decimal } from 'decimal.js';

//NOTE - don't use this directly. Opt for using the `usePointsConverters` hook as it will
// correctly select the tiers based on context
const convertPointsToCash = ({ points, conversionTiers }) => {
  if (points === null || points === undefined) return points;

  points = new Decimal(points);
  const absolutePoints = points.abs();

  const cashAmount = conversionTiers.reduce(
    (total, tier) => {
      if (absolutePoints.greaterThan(total.points)) {
        const maxPointsForTier = tier.max.minus(tier.min).dividedBy(tier.rate);
        const pointsForTier = Decimal.min(maxPointsForTier, absolutePoints.minus(total.points));

        const cashAmountForTier = pointsForTier.times(tier.rate);

        return {
          cash: total.cash.plus(cashAmountForTier),
          points: total.points.plus(pointsForTier),
        };
      } else {
        return total;
      }
    },
    { cash: new Decimal(0), points: new Decimal(0) },
  ).cash;

  const resultAmount = points.isNegative() ? cashAmount.neg() : cashAmount;
  return resultAmount.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
};

export default convertPointsToCash;
